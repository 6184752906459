/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

/* @import url("https://fonts.cdnfonts.com/css/amsi-pro-narw-ultra");
@import url("https://fonts.cdnfonts.com/css/amsi-pro-narw-black"); */

html {
  background-color: black;
}

body {
  font-family: "Archivo", sans-serif;
}

section {
  position: relative;
}

section * {
  z-index: 1;
}

nav {
  z-index: 999999;
}

#logo {
  cursor: pointer;
}

.logo {
  font-family: "Archivo", sans-serif;
}

.rainbow-button {
  background: linear-gradient(
    90deg,
    #e65cd3,
    #3faaff,
    #00cde4,
    #00be77,
    #f29600,
    #ff0000,
    #e65cd3
  );
  background-size: 600%;
  z-index: 1;
  position: relative;
}

.rainbow-button:hover {
  animation: animate 16s linear infinite;
  transition: 0.5s;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 600%;
  }
}

.rainbow-text:hover,
.rainbow {
  background-image: repeating-linear-gradient(
    90deg,
    #e65cd3,
    #3faaff,
    #00cde4,
    #00be77,
    #f29600,
    #ff0000,
    #e65cd3
  );
  background-size: 1000%;
  background-clip: text;
  clip-path: url(#text-path);
  -webkit-text-fill-color: transparent;
  animation: animate 8s ease infinite;
}

.animate-breath {
  animation: animate-breath 1s ease-in-out infinite alternate;
}

.animate-press {
  animation: animate-press 0.3s ease-in-out forwards;
}

@keyframes animate-breath {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes animate-press {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

#bg-img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 1%,
    rgba(0, 0, 0, 0) 15%,
    rgba(0, 0, 0, 0) 85%,
    rgba(0, 0, 0, 1) calc(99% - 4.25rem)
  );
}

.noise {
  height: 100%;
  background: linear-gradient(
      180deg,
      black,
      rgba(0, 0, 0, 0.5) 20%,
      rgba(0, 0, 0, 0.25) 85%
    ),
    url(https://grainy-gradients.vercel.app/noise.svg);
  filter: contrast(170%) brightness(1000%);
}

.bg-noise {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(https://grainy-gradients.vercel.app/noise.svg);
  filter: contrast(170%) brightness(1000%);
}

/* Chrome-specific */
@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .noise,
  .bg-noise {
    filter: contrast(290%) brightness(1000%);
  }
}

.isolate {
  isolation: isolate;
  position: absolute;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(23, 23, 23);
  mix-blend-mode: multiply;
  margin-top: -1px;
  height: calc(100% + 1px);
}

@media screen and (max-width: 768px) {
  .overlay {
    height: calc(100% + 2px);
  }
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 7rem;
  overflow-x: hidden;
  overflow-y: hidden;
  color: white;
  font-size: 6rem;
  font-family: "Monoton", sans-serif;
  text-transform: lowercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Archivo", sans-serif;
  font-weight: 900;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

.marquee.reverse {
  height: 5rem;
  font-size: 3rem;
}

.marquee.reverse .track {
  animation-direction: reverse;
}

@media screen and (max-width: 768px) {
  .marquee {
    height: 4rem;
    font-size: 3rem;
  }

  .marquee.reverse {
    height: 2rem;
    font-size: 1.5rem;
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.project {
  position: relative;
  transform: scale(0.925);
}

.project:hover,
.project:focus {
  animation: pop 0.25s ease-out forwards;
}

@keyframes pop {
  0% {
    transform: scale(0.925);
  }
  5% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.875);
  }
  80% {
    transform: scale(0.975);
  }
  100% {
    transform: scale(0.975);
  }
}

.project {
  border-radius: 0.5rem;
  overflow: hidden;
  display: block;
}

@media screen and (max-width: 768px) {
  .project {
    margin: 0.5rem 0;
  }
}

.project__cover {
  object-fit: contain;
}

.project__cover:hover,
.project__cover:focus {
  opacity: 0;
  transition: opacity 0.05s linear 0.2s;
}

.project__gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
}

/* community-marquee styles */
.community-marquee {
  --gap: 2rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  opacity: 0.25;
  filter: grayscale(1);
  transition: opacity 0.3s ease-in-out;
}

.community-marquee:hover,
.community-marquee:focus {
  opacity: 1;
  filter: grayscale(0);
}

.community-marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

/* Pause animation when reduced-motion is set */
@media (prefers-reduced-motion: reduce) {
  .community-marquee__content {
    animation-play-state: paused !important;
  }
}

/* Enable animation */
.enable-animation .community-marquee__content {
  animation: scroll 50s linear infinite;
}

/* Reverse animation */
.community-marquee--reverse .community-marquee__content {
  animation-direction: reverse;
}

/* Pause on hover */
.community-marquee--hover-pause:hover .community-marquee__content {
  animation-play-state: paused;
}

/* Attempt to size parent based on content. Keep in mind that the parent width is equal to both content containers that stretch to fill the parent. */
.community-marquee--fit-content {
  max-width: fit-content;
}

/* A fit-content sizing fix: Absolute position the duplicate container. This will set the size of the parent wrapper to a single child container. Shout out to Olavi's article that had this solution 👏 @link: https://olavihaapala.fi/2021/02/23/modern-marquee.html  */
.community-marquee--pos-absolute .community-marquee__content:last-child {
  position: absolute;
  top: 0;
  left: 0;
}

/* Marquee slide */
.mrq-slide-container {
  margin: 0px;
  padding: 0px;
}

.mrq-slide-item {
  width: 28rem;
  height: 28rem;
  margin-right: 2rem;
  text-align: center;
  background-color: red;
  color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  filter: grayscale(1);
  opacity: 0.33;
  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

@media screen and (max-width: 768px) {
  .mrq-slide-item {
    width: 20rem;
    height: 20rem;
  }
}

.mrq-slide-item:hover,
.mrq-slide-item:focus {
  filter: grayscale(0);
  opacity: 1;
}

.mrq-slide-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Enable position absolute animation on the duplicate content (last-child) */
.enable-animation
  .community-marquee--pos-absolute
  .community-marquee__content:last-child {
  animation-name: scroll-abs;
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% + var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

/* Other page demo styles */
.community-marquee__content__item {
  flex: 0 0 auto;
  width: 28rem;
  aspect-ratio: 1/1;
}

@media screen and (max-width: 768px) {
  .community-marquee__content__item {
    width: 20rem;
  }
}

.community-marquee__content__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  overflow: hidden;
}

#tsparticles canvas,
#tsparticles2 canvas,
#tsparticles3 canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#tsparticles3 canvas {
  height: 30%;
}

/* VINTALIGHT */

.vintalight {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px 0;
}

@media screen and (max-width: 768px) {
  .vintalight {
    display: none;
  }
}

.vintalight__container {
  position: relative;
  width: 100%;
  max-width: 12em;
  height: 12em;
  background-color: #1f1f1f;
  border-radius: 4px;
  font-size: 30px;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.3),
    0 8px 16px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1),
    box-shadow 0.5s, margin 0.5s;
}
@media screen and (max-width: 500px) {
  .vintalight__container {
    max-width: 7.35em;
    height: 8.575em;
  }
}
.vintalight__container:nth-child(1) {
  transform: translate(40px, 30px) rotate(-30deg);
  z-index: 40;
}
.vintalight__container:nth-child(1):hover {
  transform: scale(1.15) translate(40px, 30px) rotate(0);
}
@media screen and (max-width: 700px) {
  .vintalight__container:nth-child(1) {
    transform: translate(0) rotate(-30deg);
  }
  .vintalight__container:nth-child(1):hover {
    transform: scale(1.1) translate(0) rotate(0);
  }
}
.vintalight__container:nth-child(2) {
  transform: translate(30px, 20px) rotate(7deg);
  z-index: 50;
}
.vintalight__container:nth-child(2):hover {
  transform: scale(1.15) translate(30px, 20px) rotate(0);
}
@media screen and (max-width: 700px) {
  .vintalight__container:nth-child(2) {
    transform: translate(0) rotate(7deg);
  }
  .vintalight__container:nth-child(2):hover {
    transform: scale(1.1) translate(0) rotate(0);
  }
}
.vintalight__container:nth-child(3) {
  transform: translate(-20px, 20px) rotate(20deg);
  z-index: 40;
}
.vintalight__container:nth-child(3):hover {
  transform: scale(1.15) translate(-20px, 20px) rotate(0);
}
@media screen and (max-width: 700px) {
  .vintalight__container:nth-child(3) {
    transform: translate(0) rotate(20deg);
  }
  .vintalight__container:nth-child(3):hover {
    transform: scale(1.1) translate(0) rotate(0);
  }
}
.vintalight__container:nth-child(4) {
  transform: translate(-40px, -10px) rotate(-10deg);
  z-index: 30;
}
.vintalight__container:nth-child(4):hover {
  transform: scale(1.15) translate(-40px, -10px) rotate(0);
}
@media screen and (max-width: 700px) {
  .vintalight__container:nth-child(4) {
    transform: translate(0) rotate(10deg);
  }
  .vintalight__container:nth-child(4):hover {
    transform: scale(1.1) translate(0) rotate(0);
  }
}
.vintalight__container:nth-child(5) {
  transform: translate(-30px, -20px) rotate(5deg);
  z-index: 20;
}
.vintalight__container:nth-child(5):hover {
  transform: scale(1.15) translate(-30px, -20px) rotate(0);
}
@media screen and (max-width: 700px) {
  .vintalight__container:nth-child(5) {
    transform: translate(0) rotate(-20deg);
  }
  .vintalight__container:nth-child(5):hover {
    transform: scale(1.1) translate(0) rotate(0);
  }
}
.vintalight__container:nth-child(6) {
  transform: translate(30px, -40px) rotate(-10deg);
  z-index: 40;
}
.vintalight__container:nth-child(6):hover {
  transform: scale(1.15) translate(30px, -40px) rotate(0);
}
@media screen and (max-width: 700px) {
  .vintalight__container:nth-child(6) {
    transform: translate(0) rotate(-10deg);
  }
  .vintalight__container:nth-child(6):hover {
    transform: scale(1.1) translate(0) rotate(0);
  }
}
.vintalight__container:hover {
  box-shadow: 0 2px 6px 6px rgba(0, 0, 0, 0.2),
    0 8px 16px 24px rgba(0, 0, 0, 0.05);
  z-index: 100;
}
@media screen and (max-width: 700px) {
  .vintalight__container:hover {
    margin: 0 25px;
  }
}
.vintalight__container:hover .vintalight__container__photo::before {
  opacity: 1;
}
.vintalight__container.active,
.vintalight__container.active:hover {
  transform: rotate(0) scale(1.25);
}
@media screen and (max-width: 500px) {
  .vintalight__container.active,
  .vintalight__container.active:hover {
    transform: rotate(0) scale(1.15);
  }
}
.vintalight__container.active {
  position: relative;
}
.vintalight__container.active .vintalight__container__photo {
  cursor: default;
}
.vintalight__container.active .vintalight__container__photo::before {
  display: none;
}
.vintalight__container__photo {
  position: relative;
  width: 11em;
  height: 11em;
  margin: 0.5em auto 0;
  overflow: hidden;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .vintalight__container__photo {
    width: 6.475em;
    height: 6.475em;
    margin: 0.4375em auto 0;
  }
}

.vintalight__container__caption {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(40, 40, 40, 0.85);
  color: #151515;
  font-size: 0.75em;
  font-weight: 700;
  opacity: 0;
  transition: opacity 0.25s;
}

.vintalight__container__photo:hover .vintalight__container__caption {
  opacity: 1;
}
/* .vintalight__container__photo:hover {
  filter: grayscale(1);
} */

.vintalight__container__photo__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
